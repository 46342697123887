import * as React from "react"
import Seo from "../components/seo"

const SSRPage = ({ serverData }) => (
  <main>
      <Seo 
        title={serverData.message}
        // meta={"Encontrá la propiedad que estabas buscando. " + getFakeAddres(property) + " en " + getOperations(property)[0] +  " en " +  getLocation(property)} 
        // description={"Encontrá la propiedad que estabas buscando. " + getFakeAddres(property) + " en " + getOperations(property)[0] +  " en " +  getLocation(property)} 
        image={serverData.message}
    />
    <h1>SSR Page with Dogs</h1>
    <img alt="Happy dog" src={serverData.message} />
  </main>
)
export default SSRPage
export async function getServerData() {
  try {
    const res = await fetch(`https://dog.ceo/api/breeds/image/random`)
    if (!res.ok) {
      throw new Error(`Response failed`)
    }
    return {
      props: await res.json(),
    }
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {}
    }
  }
}